<template>
  <b-card>
    <div>
      <!-- User Info: Input Fields -->
      <validation-observer ref="formStaff">
        <b-form @submit.prevent="handleSubmit">
          <!-- Header: Personal Info -->
          <div class="d-flex">
            <h4 class="mb-0">Thông tin đội</h4>
          </div>
          <b-row class="mt-1">
            <b-col>
              <validation-provider #default="{ errors }" name="name" rules="required">
                <b-form-group label="Tên đội" label-for="team-name">
                  <b-form-input id="team-name" v-model="team.name" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col>
              <b-form-group label="Leader" label-for="team-name">
                <v-select
                  v-model="team.leader"
                  label="name"
                  :reduce="(item) => item.id"
                  :options="leaderOptions"
                  placeholder="Thêm leader"
                  class="w-100"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <div class="d-flex mt-2">
            <h4 class="mb-0">Thông tin chiến dịch</h4>
          </div>
          <b-row class="mt-1">
            <b-col>
              <v-select
                v-model="teamCampaigns"
                multiple
                label="name"
                :reduce="(item) => item.id"
                :options="campaignOptions"
                placeholder="Thêm chiến dịch"
                class="w-100"
              />
            </b-col>
          </b-row>

          <b-row class="mt-2 justify-content-center">
            <!-- Action Buttons -->
            <b-button
              variant="outline-secondary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :to="{ name: constRouter.TEAM.name }"
            >
              Hủy
            </b-button>
            <b-overlay :show="loading" block opacity="0.6" spinner-variant="primary">
              <b-button type="submit" variant="primary" block> Thêm </b-button>
            </b-overlay>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BFormRadioGroup,
  BCard,
  BAvatar,
  BMedia,
  BInputGroupAppend,
  BOverlay,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import constRouter from '@/constants/constRouter';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import vSelect from 'vue-select';
import { teamCreate, teamListCampaign } from '@/apis/apiTeam';
import { list } from '@/apis/apiStaff';

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BFormRadioGroup,
    BCard,
    BAvatar,
    BMedia,
    BInputGroupAppend,

    flatPickr,

    ValidationProvider,
    ValidationObserver,

    vSelect,
    BOverlay,
  },
  data: () => ({
    constRouter,
    required,
    loading: false,
    team: {
      name: undefined,
      leader: undefined,
    },
    teamCampaigns: [],
    campaignOptions: [],
    leaderOptions: [],
  }),
  created() {
    this.fetchOptionsSelect();
  },
  methods: {
    fetchOptionsSelect() {
      const campaignOptionsApi = teamListCampaign();
      const leaderOptionsApi = list();

      Promise.all([campaignOptionsApi, leaderOptionsApi]).then((res) => {
        const campaignOptions = res[0]?.data.data;
        const leaderOptions = res[1]?.data.data;

        this.campaignOptions = campaignOptions;
        this.leaderOptions = leaderOptions;
      });
    },
    handleSubmit() {
      const form = this.$refs.formStaff;
      form.validate().then((success) => {
        if (success) {
          this.loading = true;
          const { name, leader } = this.team;

          const payload = {
            name,
            user_id: leader,
            campaigns: this.teamCampaigns,
          };

          teamCreate(payload)
            .then((res) => {
              this.loading = false;
              const { data } = res.data;
              this.$router
                .push({ name: constRouter.TEAM_VIEW.name, params: { id: data?.id } })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Thêm đội',
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                      text: 'Thêm đội thành công!',
                    },
                  });
                });
            })
            .catch((err) => {
              this.loading = false;
              if (err.response.status === 422) {
                form.setErrors(err.response.data.errors);
              }
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Thêm đội',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: 'Thêm đội thất bại!',
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
